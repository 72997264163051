.App {
  text-align: center;
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.Canvas1  {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.Counter {
  text-align: center;
  background-color: black;
  width: 100vw;
  height: 5vh;
  position : absolute;
  z-index: 99;
  color: white;
}
